import request from '@/utils/requestV2'
const qs = require('qs')

// 获取任务类型条件
export function gettaskitemtypelist (data) {
  return request({
    url: '/ooh-msp/v2/settlement/gettaskitemtypelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取资源类型条件
export function getresourcetypelist (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getresourcetypelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 批量确认费用明细
export function confirmcharge (data) {
  return request({
    url: '/ooh-msp/v2/settlement/confirmcharge',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取供应商对账资源明细
export function getsettlementdetailpage (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getsettlementdetailpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 运维对账汇总数据
export function getsettlementcoststatistics (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getsettlementcoststatistics',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 费用修改
export function modifycharge (data) {
  return request({
    url: '/ooh-msp/v2/settlement/modifycharge',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取任务发起方（原结算方）
export function getsettlements (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getsettlements',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取供应商
export function getsuppliers (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getsuppliers',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 任务费用修改记录明细
export function getFeeModifyList (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getfeemodifylist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 对账时间查询类别
export function gettimecategory (data) {
  return request({
    url: '/ooh-msp/v2/settlement/gettimecategory',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取对账页面内容详情
export function getsettlementpagecontent (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getsettlementpagecontent',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 任务费用修改记录明细
export function getfeemodifylist (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getfeemodifylist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 批量修改任务结算方
export function batchmodifysettlement (data) {
  return request({
    url: '/ooh-msp/v2/settlement/batchmodifysettlement',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 新版质检中，获取单项费用修改记录
 * @param {Object} data
 * @returns
 */
export function getChargeModifyList (data) {
  return request.post('/ooh-msp/v2/settlement/getchargemodifylist', qs.stringify(data))
}

// 分页获取工单明细
export function getPlanDetailPage (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getPlanDetailPage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取工单金额汇总
export function getPlanDetailAmountStatistic (data) {
  return request({
    url: '/ooh-msp/v2/settlement/getPlanDetailAmountStatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 下载供应商对账资源明细
export function createsettlementdetail (data) {
  return request({
    url: '/ooh-msp/v2/settlement/createsettlementdetail',
    method: 'get',
    params: data
  })
}
